import React from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import Testimonials from "../components/Repeating/Testimonials";
import About from "../components/Repeating/About";
import StatesWeServe from "../components/Repeating/StatesWeServe";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";

const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";
  return (
    <Layout>
      <SearchEngineOptimization
        title="Home Loan Process | Right Start | Mortgage Lender"
        description="Discover the 5 simple steps to getting a loan, which start with determining how much you can afford. At Right Start, we walk you through it step-by-step."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 md:mb-28 md:pt-12">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <StaticImage
                src="../images/5.0 Loan Process/1.0 loan process-hero.png"
                loading="eager"
                width={560}
              />
            </div>
            <div>
              <h1>Your Guide to the Home Loan Process</h1>
              <p>
                At Right Start, our goal is to make your home buying journey as
                smooth as possible, and for us, that means being there for you
                during the loan process. You might feel daunted heading in, but
                we’re here to walk you through it step-by-step.
              </p>
              <ButtonSolid
                href="/request-rates/"
                text="Request Rates"
                altStyle={2}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 bg-primary-50 py-20 md:mb-32 md:py-32">
        <div className="container">
          <header className="mb-12 md:mb-24">
            <h2>5 Simple Steps to Getting a Loan</h2>
          </header>

          <div className="grid md:grid-cols-12 md:gap-x-14 lg:gap-x-18">
            <ScrollNavigation className="hidden md:col-span-4 md:col-start-1 md:block">
              <li>
                <AnchorLink
                  to={url + "#section-1"}
                  title="Loan Amount"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-2"}
                  title="Select Program"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink to={url + "#section-3"} title="Apply" stripHash />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-4"}
                  title="Processing"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink to={url + "#section-5"} title="Closing" stripHash />
              </li>
            </ScrollNavigation>

            <div className="space-y-12 md:col-span-8 md:col-end-13 md:space-y-24">
              <div
                id="section-1"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h3 className="heading-four">
                  Step 1 - Determine how much you can afford
                </h3>
                <p className="mb-0">
                  <Link to="/mortgage-calculators/">Mortgage calculators</Link>{" "}
                  will be your friend—they’ll help you determine exactly how
                  much you can afford to pay on a home loan each month. You
                  might have an idea already, but it’s a smart move to take time
                  early on to evaluate your finances.
                </p>
              </div>

              <div
                id="section-2"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <header className="mb-8 md:mb-10">
                  <h3 className="heading-four">
                    Step 2 - Select a loan program
                  </h3>
                  <p>
                    You have options, and it’s important to know what programs
                    you might qualify for when considering a home loan.
                  </p>
                </header>

                <div className="space-y-6">
                  <Link
                    to="/fha-home-loans/"
                    className="flex w-full flex-wrap items-center rounded-md border border-gray-200 p-4 font-normal text-typography-body no-underline hover:border-primary-500 hover:text-typography-body md:flex-nowrap md:space-x-6"
                  >
                    <div className="mb-4 w-full md:mb-0 md:w-auto">
                      <StaticImage
                        src="../images/5.0 Loan Process/FHA.jpg"
                        loading="lazy"
                        width={144}
                      />
                    </div>
                    <div className="flex-1">
                      <h4 className="heading-six mb-2">FHA</h4>
                      <p className="mb-0">
                        FHA loans are perfect for first-time home buyers—they
                        offer low down payments and have reasonable income and
                        credit expectations.
                      </p>
                    </div>
                  </Link>

                  <Link
                    to="/fha-home-loans/"
                    className="flex w-full flex-wrap items-center rounded-md border border-gray-200 p-4 font-normal text-typography-body no-underline hover:border-primary-500 hover:text-typography-body md:flex-nowrap md:space-x-6"
                  >
                    <div className="mb-4 w-full md:mb-0 md:w-auto">
                      <StaticImage
                        src="../images/5.0 Loan Process/VA.jpg"
                        loading="lazy"
                        width={144}
                      />
                    </div>
                    <div className="flex-1">
                      <h4 className="heading-six mb-2">VA</h4>
                      <p className="mb-0">
                        VA loans offer exceptional benefits for military
                        veterans and active service members, such as low
                        interest rates and no money down.
                      </p>
                    </div>
                  </Link>

                  <Link
                    to="/fha-home-loans/"
                    className="flex w-full flex-wrap items-center rounded-md border border-gray-200 p-4 font-normal text-typography-body no-underline hover:border-primary-500 hover:text-typography-body md:flex-nowrap md:space-x-6"
                  >
                    <div className="mb-4 w-full md:mb-0 md:w-auto">
                      <StaticImage
                        src="../images/5.0 Loan Process/Conventional.jpg"
                        loading="lazy"
                        width={144}
                      />
                    </div>
                    <div className="flex-1">
                      <h4 className="heading-six mb-2">Conventional</h4>
                      <p className="mb-0">
                        A conventional loan is a great option for those with a
                        solid credit score and minimal debt.
                      </p>
                    </div>
                  </Link>

                  <Link
                    to="/fha-home-loans/"
                    className="flex w-full flex-wrap items-center rounded-md border border-gray-200 p-4 font-normal text-typography-body no-underline hover:border-primary-500 hover:text-typography-body md:flex-nowrap md:space-x-6"
                  >
                    <div className="mb-4 w-full md:mb-0 md:w-auto">
                      <StaticImage
                        src="../images/5.0 Loan Process/USDA.jpg"
                        loading="lazy"
                        width={144}
                      />
                    </div>
                    <div className="flex-1">
                      <h4 className="heading-six mb-2">USDA</h4>
                      <p className="mb-0">
                        If your dream home is in a rural area, a USDA loan
                        offers no down payment and a minimal investment.
                      </p>
                    </div>
                  </Link>

                  <Link
                    to="/fha-home-loans/"
                    className="flex w-full flex-wrap items-center rounded-md border border-gray-200 p-4 font-normal text-typography-body no-underline hover:border-primary-500 hover:text-typography-body md:flex-nowrap md:space-x-6"
                  >
                    <div className="mb-4 w-full md:mb-0 md:w-auto">
                      <StaticImage
                        src="../images/5.0 Loan Process/Jumbo.jpg"
                        loading="lazy"
                        width={144}
                      />
                    </div>
                    <div className="flex-1">
                      <h4 className="heading-six mb-2">Jumbo</h4>
                      <p className="mb-0">
                        If you’re interested in higher-priced homes, you can
                        apply for a jumbo loan to finance one that exceeds
                        conforming loan limits.
                      </p>
                    </div>
                  </Link>
                </div>
              </div>

              <div
                id="section-3"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h3 className="heading-four">Step 3 - Get pre-approved</h3>
                <p className="mb-0">
                  Now that you know how much you can afford and have selected a
                  loan program, it’s time to submit your application and get
                  pre-approved. Sellers love pre-approved buyers because they
                  know they have the loan to back up an offer. On the other
                  hand, a pre-qualified buyer is much less desirable because
                  they only have an estimate of what they might be able to
                  borrow.
                </p>
              </div>

              <div
                id="section-4"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h3 className="heading-four">
                  Step 4 - Complete loan processing
                </h3>
                <p className="mb-0">
                  Many of the loan requirements were already completed during
                  pre-approval, but there are a few more items on the list to
                  make the loan official. You might need to provide additional
                  documentation for underwriting, for example. And when a seller
                  accepts your offer on a home, your lender will want to see the
                  Purchase Agreement and proof of your earnest money deposit.
                </p>
              </div>

              <div
                id="section-5"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h3 className="heading-four">Step 5 - Close on the Loan</h3>
                <p className="mb-0">
                  When it comes time to close on your loan and get the keys to
                  your new home, you’ll have a big stack of papers to sign. One
                  of the more important documents to pay close attention to is
                  the Closing Disclosure, which confirms the costs of your loan.
                  When you first applied, you received a loan estimate, and
                  those figures can change by the time you close.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ValueProps />
      <About />
      <Testimonials />
      <StatesWeServe />
      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Loan Process.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Loan Process.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
